






























/**
 * 本画面 (P0247) は、<item-detail>, <item-detail-success>, <item-detail-failure> を子コンポーネントとして持つ。
 * ここで、本画面に関連するイベントハンドラの定義は <p0247> に集約することとし、<item-detail> はイベント
 * "click-sign-in", "click-exchange" を単に emit するのみとする。
 *
 * <p0247>
 * ├── <item-detail>
 * │   ├── <op-calculation-panel>
 * │   └── <op-exchange-footer>
 * ├── <item-detail-success>
 * │   └── <op-calculation-panel>
 * └── <item-detail-failure>
 */

import ItemDetail from '@/components/OdakyuPoint/OpExchange/ItemDetail.vue';
import ItemDetailFailure from '@/components/OdakyuPoint/OpExchange/ItemDetailFailure.vue';
import ItemDetailSuccess from '@/components/OdakyuPoint/OpExchange/ItemDetailSuccess.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { OpCardRepository } from '@/repositories/OpCardRepository';
import { OpExchangeItem } from '@/models/OpExchangeItem';
import { OpExchangeItemCmsRepository } from '@/repositories/OpExchangeItemCmsRepository';

const OP_EXCHANGE_ITEM_ID_KEY = 'op_exchange_item_id';
const OP_EXCHANGE_ITEM_NAME_KEY = 'op_exchange_item_name';

@Component({
  components: {
    ItemDetail,
    ItemDetailFailure,
    ItemDetailSuccess
  }
})
export default class P0247 extends Vue {
  /**
   * `id` は本画面に表示するポイント交換商品のコンテンツ ID (microCMS) を保持する。
   */
  @Prop() id!: string;

  /**
   * `item` は本画面に表示するポイント交換商品を保持する。
   */
  item = getEmptyOpExchangeItem(
    this.$store.state.isSP,
    this.$store.state.supportsWebP
  );

  /**
   * `isExchanged` には、本画面から Titan ポイント交換 API を呼び出し済みであれば true がセットされる。
   */
  isExchanged = false;

  /**
   * `error` は Titan ポイント交換 API 呼び出しの結果として生起したエラーメッセージを保持する。
   */
  error = '';

  /**
   * `skuItemName` はユーザが選択している SKU 単位商品名を保持する。
   * 当該ポイント交換商品が SKU 単位商品を持たない場合、空文字列となる。
   */
  skuItemName = '';

  /**
   * `isModalOpen` が true ならばポイント交換確認モーダルが表示される。
   */
  isModalOpen = false;

  /**
   * `pointBalanceSnapshot` はユーザのメインカードの小田急ポイント残高スナップショットを表す。
   * コンポーネント <op-calculation-panel> をポイント交換後の画面でも表示するために本値が必要となる。
   */
  pointBalanceSnapshot = 0;

  readonly cmsRepo = new OpExchangeItemCmsRepository(
    this.$store.state.isSP,
    this.$store.state.supportsWebP
  );
  readonly titanRepo = new OpCardRepository();

  async created() {
    localStorage.removeItem(OP_EXCHANGE_ITEM_ID_KEY);
    localStorage.removeItem(OP_EXCHANGE_ITEM_NAME_KEY);
    const item = await this.cmsRepo.getItem(this.id);
    if (item) {
      this.item = item;
      if (item.skuItemNames.length) {
        this.skuItemName = item.skuItemNames[0].fieldValue;
      }
    }
  }

  async onClickSignIn() {
    if (this.isUserSignedIn) {
      return;
    }
    localStorage.setItem(OP_EXCHANGE_ITEM_ID_KEY, this.item.id);
    localStorage.setItem(OP_EXCHANGE_ITEM_NAME_KEY, this.item.itemName);
    this.$auth.loginWithRedirect({
      appState: { targetUrl: this.$route.fullPath }
    });
  }

  async onClickExchange() {
    if (!this.isUserSignedIn) {
      return;
    }
    this.pointBalanceSnapshot = this.pointBalance;
    try {
      await this.titanRepo.postOpExchange(
        this.odakyuCustomerNo,
        this.item.pointAmount,
        this.item.id,
        this.skuItemName || undefined
      );
    } catch (errCode) {
      this.error = this.handlePostOpExchangeErr(errCode);
    } finally {
      const balance = await this.titanRepo.getOpBalance();
      this.$store.commit('setOpBalance', balance);
      this.isModalOpen = false;
      this.isExchanged = true;
      window.scrollTo(0, 0);
    }
  }

  handlePostOpExchangeErr(errCode: unknown) {
    switch (errCode) {
      case 40050:
        return this.$msg.get('2000501');
      case 42210:
        return this.$msg.get('2000503', {
          exchangeLimitNum: this.item.exchangeLimitNum
        });
      case 50030:
      case 50034:
        return this.$msg.get('2000502');
      case 50301:
        return this.$msg.get('2000425');
      case 50305:
        return this.$msg.get('2000504');
      default:
        return this.$msg.get('2000500');
    }
  }

  get odakyuCustomerNo(): string {
    const cards: {
      odakyuCustomerNo: string;
      isMain: boolean;
    }[] = this.$store.state.op.cards;
    const card = cards.find(c => c.isMain);
    return (card && card.odakyuCustomerNo) || '';
  }

  get pointBalance(): number {
    return this.$store.state.op.balance.previousBalance;
  }

  get isUserSignedIn(): boolean {
    return this.$auth.isAuthenticated && !this.$auth.loading;
  }
}

/**
 * 空の OpExchangeItem を作成し返却する。
 * @param {boolean} isSP
 * @param {boolean} supportsWebP
 * @returns {OpExchangeItem}
 */
function getEmptyOpExchangeItem(
  isSP: boolean,
  supportsWebP: boolean
): OpExchangeItem {
  return OpExchangeItem.valueOf(
    {
      id: '',
      createdAt: '',
      updatedAt: '',
      publishedAt: '',
      revisedAt: '',
      itemName: '',
      skuItemNames: [],
      store: {
        id: '',
        createdAt: '',
        updatedAt: '',
        publishedAt: '',
        revisedAt: '',
        display: '',
        companyId: '',
        deptId: '',
        storeId: ''
      },
      pointAmount: 0,
      categories: [],
      contactInfoName: '',
      applicationMethod: [],
      image1: { url: '', width: 0, height: 0 },
      itemClass: []
    },
    isSP,
    supportsWebP
  );
}
