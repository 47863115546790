import { Image, OpExchangeStoreResponse } from '@/models/cms';
import { OpExchangeItemResponse } from '@/models/cms';
import { convertOptimizedUrl, isNewContent } from '@/common/cmsUtils';

export class OpExchangeItem {
  readonly APPLICATION_METHOD_LABEL_CAMPAIGN = 'キャンペーン申込';
  readonly APPLICATION_METHOD_LABEL_PHONE = '電話申込';
  readonly APPLICATION_METHOD_LABEL_WEB = 'WEB申込';

  static valueOf(
    r: OpExchangeItemResponse,
    isSP: boolean,
    supportsWebP: boolean
  ) {
    return new OpExchangeItem(
      isSP,
      supportsWebP,
      r.id,
      r.createdAt,
      r.updatedAt,
      r.publishedAt,
      r.revisedAt,
      r.itemName,
      r.skuItemNames,
      r.store,
      r.pointAmount,
      r.regularPrice,
      r.categories,
      r.contactInfoName,
      r.contactInfoEmail,
      r.contactInfoPhone,
      r.applicationMethod,
      r.content1,
      r.content2,
      r.content3,
      r.image1,
      r.image2,
      r.image3,
      r.image4,
      r.image5,
      r.image6,
      r.image7,
      r.image8,
      r.itemClass,
      r.divisionId,
      r.minExchangeDays,
      r.exchangeLimitNum
    );
  }

  private constructor(
    readonly isSP: boolean,
    readonly supportsWebP: boolean,
    readonly id: string,
    readonly createdAt: string,
    readonly updatedAt: string,
    readonly publishedAt: string,
    readonly revisedAt: string,
    readonly itemName: string,
    readonly skuItemNames: { fieldId: string; fieldValue: string }[],
    readonly store: OpExchangeStoreResponse,
    readonly pointAmount: number,
    readonly regularPrice: number | undefined,
    readonly categories: string[],
    readonly contactInfoName: string | undefined,
    readonly contactInfoEmail: string | undefined,
    readonly contactInfoPhone: string | undefined,
    readonly applicationMethod: string[],
    readonly content1: string | undefined,
    readonly content2: string | undefined,
    readonly content3: string | undefined,
    readonly image1: Image,
    readonly image2: Image | undefined,
    readonly image3: Image | undefined,
    readonly image4: Image | undefined,
    readonly image5: Image | undefined,
    readonly image6: Image | undefined,
    readonly image7: Image | undefined,
    readonly image8: Image | undefined,
    readonly itemClass: string[],
    readonly divisionId: string | undefined,
    readonly minExchangeDays: number | undefined,
    readonly exchangeLimitNum: number | undefined
  ) {}

  get isNew(): boolean {
    return isNewContent(this.publishedAt);
  }

  get isApplicationMethodWeb(): boolean {
    return this.applicationMethod.includes(this.APPLICATION_METHOD_LABEL_WEB);
  }

  get topImageUrl(): string {
    return (
      this.image1.url &&
      convertOptimizedUrl(this.image1, this.isSP, this.supportsWebP)
    );
  }

  get imageUrls(): string[] {
    const f = convertOptimizedUrl;
    const isSP = this.isSP;
    const supportsWebP = this.supportsWebP;
    const images = [];
    this.image1?.url && images.push(f(this.image1, isSP, supportsWebP));
    this.image2?.url && images.push(f(this.image2, isSP, supportsWebP));
    this.image3?.url && images.push(f(this.image3, isSP, supportsWebP));
    this.image4?.url && images.push(f(this.image4, isSP, supportsWebP));
    this.image5?.url && images.push(f(this.image5, isSP, supportsWebP));
    this.image6?.url && images.push(f(this.image6, isSP, supportsWebP));
    this.image7?.url && images.push(f(this.image7, isSP, supportsWebP));
    this.image8?.url && images.push(f(this.image8, isSP, supportsWebP));
    return images;
  }
}
